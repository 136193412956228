import React, { useState, useEffect} from 'react';
import gsap from 'gsap'
import {ScrollToPlugin} from 'gsap/ScrollToPlugin'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import axios from 'axios'

import './App.css';

import Stream from './components/stream'
import Events from './components/events'
import Footer from './components/footer'
import AboutCopy from './components/aboutCopy'

gsap.registerPlugin(ScrollToPlugin)
gsap.registerPlugin(ScrollTrigger)

function scrollTo(el) {
  gsap.to(window, {
    duration: 0.6,
    ease: 'power3.inOut',
    scrollTo: el,
  });
}

function App() {
  const [events, setEvents] = useState({
    events: [],
    loading: true,
  });

  useEffect(() => {

    gsap.to('.artwork', {
      ease: 'none',
      y: -400,
      scrollTrigger: {
        trigger: "body",
        start: "top top",
        end: "bottom top",
        scrub: true
      }
    })

    async function fetchData() {
      const result = await axios('/events.json', {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setEvents({events: result.data.events, loading: false});
    }
    fetchData();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div className="App-header__headings">
          <a href="/">
            <img src="/logo.svg" alt="Drawing in The Academy Logo"/>
          </a>
        </div>
        <nav>
          <a href="#stream" onClick={(e)=> {e.preventDefault(); scrollTo('.stream__wrap')}}>Stream</a>
          <a href="#sessions" onClick={(e)=> {e.preventDefault(); scrollTo('.events__wrap')}}>Schedule</a>
        </nav>
      </header>

      <img className="artwork" src="/artwork2 copy.png" alt=""/>
      <div className="date-circle">
        <div>06</div>
        <div>october</div>
        <div>2020</div>
      </div>

      <Stream streamUrl="https://www.youtube.com/embed/7RmcJHNGgtc"/>
      <section className="about__section">
        <h2>About</h2>
        <AboutCopy/>
      </section>

      <Events events={events}/>

      <Footer/>
    </div>
  );
}

export default App;
