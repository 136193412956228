import React from 'react';

function AboutCopy() {
  return (
    <div className="about__copy">
        <p>The Drawing Education Network has been established to critique, question and promote the role of Drawing within Design Education. The 21st Century has seen radical transformation and the changing face of the Academy. Drawing although a timeless skill and technique for recording, observing and transmitting complex knowledge about the world needs constant critique. This event seeks to connect global scholars in discussion and collaboration through drawing. </p>
        <p>“Drawing continues seemingly unfazed - even energised - responding to and feeding off copious new ways to make images - electronically and mechanically - on film, video and computers. Images are being made today we can legitimately call drawing that would not have been possible or even imaginable at one time. The title of the newly founded Design Education Network’s inaugural session couldn’t be more apt and relevant” - Professor Paul Egglestone</p>  
    </div>
  );
}

export default AboutCopy;
