import React from 'react';
import Flickity from 'react-flickity-component'

import EventBlock from './eventBlock'

// function padLeft(nr, n, str) {
//     return Array(n - String(nr).length + 1).join(str || '0') + nr;
// }

function isCurrent(time) {
    // var t = padLeft(time, 4)

    // var d = new Date();
    // var h = padLeft(d.getHours(), 2);
    // var m = padLeft(d.getMinutes(), 2);
    
    // // console.log(parseInt(t), parseInt(`${h}${m}`), h, m)
    // if(parseInt(t) < parseInt(`${h}${m}`)) {
    //     return false;
    // }
    
    return true;
}

function Events({events}) {
    const flickityOptions = {
        initialIndex: 0,
        cellAlign: 'left',
        // freeScroll: true
        groupCells: true,
    }

    if(events.loading){
        return (
            <div className="events__wrap">
                <span className="events__loading">events loading...</span>
            </div>
        )
    }

    return (
        <div className="events__section">
            <h2>Schedule</h2>
            <Flickity className="events__wrap" options={flickityOptions}>
                {events.events.filter((event) => isCurrent(event.time)).map((event) => (
                    <EventBlock event={event}/>
                ))}
            </Flickity>
        </div>
    );
}

export default Events;
