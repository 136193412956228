import React from 'react';

function Stream({streamUrl}) {
  return (
    <div className="stream__wrap">
        <div className="steam__sizelimit">
            <div className="stream__container">
                <iframe src={streamUrl} frameBorder="0" className="stream__iframe" title="stream" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        </div>
    </div>
  );
}

export default Stream;
