import React from 'react';

function Footer() {
  return (
    <footer className="flex space-between">
        
        <div className="footer__logos">
            <a href="https://www.newcastle.edu.au/" target="_blank" rel="noopener noreferrer">
                <img src="/uon.svg" alt="The University of Newcastle Logo"/>
            </a>
            <a href="https://fastlab.soci.org.au/" target="_blank" rel="noopener noreferrer">
                <img src="/fastlab.svg" alt="Fastlab Logo"/>
            </a>
            <a href="/">
                <img src="/dita_logo.svg" alt="Drawing in The Academy Logo"/>
            </a>
        </div>

        <div>
            &copy; 2020
        </div>

    </footer>
  );
}

export default Footer;
