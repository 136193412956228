import React from 'react';

function tConv24(time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = (H % 12) || 12;
    h = (h < 10) ? ("0" + h) : h; // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ':' + ts.substr(2, 3) + ampm;
    return ts;
};

function EventBlock({event}) {
    return (
        <div className="event__block" key={event.id}>
            <div className="flex space-between">
                <h3 className="event__title">{event.title}</h3>
                <p className="event__time">{tConv24(event.time)}</p>
            </div>
            <div className="event__description" dangerouslySetInnerHTML={{__html: event.description}}></div>
        </div>
    );
}

export default EventBlock;
